import {documentReady} from "./document-ready";
import {setupOAuthPopup} from "./oauth-popup";
import {silentLoginCallback} from "@cbar/cbar-oauth";
import cssVars from "css-vars-ponyfill";
import {setupLogoutHandler} from "./handle-logout";
import {setupOrganisationDropdownListeners} from "./header";

// CSS Variables Polyfill for legacy browsers
cssVars();

documentReady().then(() => {
    setupOAuthPopup();
    setupLogoutHandler();
    silentLoginCallback();
    setupOrganisationDropdownListeners();
    loadCustomStyles();

    if (detectIE() !== false) {
        document.getElementsByTagName("html")[0].className = "ie";
    }
});

const loadCustomStyles = () => {
    const stylesheet = document.querySelector('#customStylePlaceholder');
    const cbarStyles = document.querySelector('#cbar-styles');
    const cbarBaseStyles = document.querySelector('#cbar-basic-styles');

    if(!stylesheet || !stylesheet.parentNode){
        return;
    }

    stylesheet.setAttribute('include', 'base-styles');

    var customStyle = document.createElement('custom-style');
    stylesheet.parentNode.insertBefore(customStyle, stylesheet);
    customStyle.appendChild(stylesheet);

    if (cbarStyles) {
        loadCbarStyles(cbarStyles, 'cbar-shared-styles');
    }

    if(cbarBaseStyles){
        loadCbarStyles(cbarBaseStyles, 'cbar-base-styles');
    }
}

const loadCbarStyles = (cbarStyles : Element, styleToInclude: string) => {
    if(!cbarStyles.parentNode){
        return;
    }

    let customStyle = document.createElement('custom-style');

    cbarStyles.setAttribute('include', styleToInclude);
    cbarStyles.parentNode.insertBefore(customStyle, cbarStyles);
    customStyle.appendChild(cbarStyles);
}

const detectIE = (): boolean | number => {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        var rv = ua.indexOf('rv:');
        return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge (IE 12+) => return version number
        return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
    }

    // other browser
    return false;
}
