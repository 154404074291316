export function setupOrganisationDropdownListeners() {
    const organisationDropdown = document.querySelector('.header_group_select') as any;
    if (!organisationDropdown) {
        return;
    }

    organisationDropdown.addEventListener('selected-item-changed', (e) => {
        const val = e.detail.value;

        if(!val) {
            return;
        }

        const form = getForm(organisationDropdown);

        var input = document.createElement('input');
        input.setAttribute('name', 'id');
        input.setAttribute('value', val.id);
        input.setAttribute('type', 'hidden')

        form.appendChild(input);//append the input to the form

        form.submit();
    });
}

function getForm(element) {
    do {
        let parent = element.parentNode;
        if (parent.nodeName === 'FORM') {
            return parent;
        }
    } while (parent !== null);

    return null;
}