export const documentReady = async () => {
    if (document.readyState !== 'loading') {
        // Already ready.
        return true;
    }

    return new Promise((res, rej) => {
        document.addEventListener('DOMContentLoaded', e => {
            res(e);
        });
    });
};
