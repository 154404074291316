import 'whatwg-fetch';

export const setupLogoutHandler = () => {
    const header = document.querySelector('cbar-header');
    if(!header){
        return;
    }

    header.addEventListener('logout', handleLogout);
};

const handleLogout = (e) => {
    e.preventDefault();

    const header = document.querySelector('cbar-header');
    const logoutDialog = document.querySelector('cbar-app-logout');

    if(!header || !logoutDialog){
        return;
    }

    // @ts-ignore
    logoutDialog.image = header.profileImageUrl;
    // if not set this will default to the property default (authentickated)
    // @ts-ignore
    logoutDialog.toggle();

    logoutDialog.addEventListener('auth-logout', () => {
        window.location.href = "/auth/logout";
    });

    logoutDialog.addEventListener('app-logout', async () => {
       await fetch('/auth/clearsession');
        window.location.href = "/auth/logout?loggedout=true";
    });
};