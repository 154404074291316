import {DEFAULT_POPUP_FEATURES, OAuthPopup} from "@cbar/oauth-popup";
import {startSilentLogin} from '@cbar/cbar-oauth';
import {PaperSpinnerElement} from "@polymer/paper-spinner/paper-spinner";
import 'whatwg-fetch';

const getCenterOfElement = element => {
    const rect = element.getBoundingClientRect();

    return {
        x: rect.left + rect.width / 2,
        y: rect.top + rect.height / 2,
    };
};

const loginElement = document.querySelector('#login-url');
let authUrl: string | null = null;

if (!!loginElement && !!loginElement.getAttribute('value')) {
    authUrl = loginElement.getAttribute('value');
}

export const setupOAuthPopup = () => {
    if (OAuthPopup.isPopupWindow()) {
        // We're inside the OAuth popup.
        return handlePopupCallback();
    }

    const popup = new OAuthPopup;
    const loginButton: HTMLButtonElement = document.querySelector('#login-button') as HTMLButtonElement;
    const registerButton: HTMLButtonElement = document.querySelector('#register-button') as HTMLButtonElement;
    const spinner: PaperSpinnerElement = document.querySelector('paper-spinner') as PaperSpinnerElement;
    const loadingSection : HTMLDivElement = document.querySelector('#loading-indicator') as HTMLDivElement;
    const header = document.querySelector('cbar-header');

    const handler = function (this: HTMLButtonElement, event, register = false) {
        const e = event as MouseEvent;
        if (typeof e.preventDefault === "function") {
            e.preventDefault();
        }

        let loginLink = authUrl;
        if (register) {
            loginLink += "&register=1";
        }

        const buttonCenter = getCenterOfElement(this);

        // Open the popup around the clicked button
        let features = {
            ...DEFAULT_POPUP_FEATURES,
            top: Math.max(buttonCenter.y - DEFAULT_POPUP_FEATURES.height / 2),
            left: Math.max(buttonCenter.x - DEFAULT_POPUP_FEATURES.width / 2, 0),
        };

        if (popup.isPopupOpen()) {
            // Popup is already open, calling open now will just
            // focus the existing popup.
            return popup.open(authUrl, features);
        }

        loginButton.hidden = true;
        if(!!registerButton) {
            registerButton.hidden = true;
        }
        spinner.active = true;

        // Update the button text
        const btnText = loadingSection.innerHTML;
        loadingSection.innerHTML = 'Enter your login details in the popup...';

        popup.open(loginLink, features)
            .then(({redirectUrl}) => {
                loadingSection.innerHTML = 'Redirecting you now...';

                window.location.href = redirectUrl;
            }).catch(e => {
            console.error(e);
            loginButton.hidden = false;
            if(!!registerButton) {
                registerButton.hidden = false;
            }
            spinner.active = false;
            loadingSection.innerHTML = btnText;
        });

        window.addEventListener('beforeunload', () => {
            // Close the popup if the window unloads.
            popup.close();
        });
    };

    if (!!header && !!loginButton) {
        // Start a timer to see if the popup gets blocked
        loginButton.addEventListener('click', handler.bind(loginButton));
        header.addEventListener('signup-clicked', handler.bind(loginButton, true));
        header.addEventListener('signin-clicked', handler.bind(loginButton));
    }

    // were on the login page here
    if(!!registerButton){
        registerButton.addEventListener('click', handler.bind(registerButton, true));
        setTimeout(() => {
            checkLoggedInStatus(loginButton, registerButton, spinner, loadingSection);
        }, 500);
    }
};

export const handlePopupCallback = () => {
    const isLoginPage = window.location.pathname.indexOf('/auth/login') === 0;

    if (isLoginPage) {
        // We got redirected back to the login page in the popup,
        // let's re-start the login process.

        // Hide the page so that the login page does not flash in before we redirect.
        document.body.style.display = 'none';

        if (!!authUrl) {
            return window.location.href = authUrl;
        }
    }

    // Retrieve redirect url from the hidden input in the /auth/social view
    const input = document.querySelector('#redirect_url') as HTMLInputElement;
    const redirectUrl = window.location ? window.location.pathname : '/dashboard';

    // Attempt to notify if we're already in a popup.
    OAuthPopup.notifyOpener({redirectUrl});
};

const checkLoggedInStatus = (loginButton, registerButton, loadingSpinner, loadingDiv) => {
    const showLoginButton = () => {
        loginButton.hidden = false;

        if (registerButton) {
            registerButton.hidden = false;
        }

        loadingDiv.innerHTML = "";
        loadingSpinner.active = false;
    };

  startSilentLogin({ authUrl: authUrl! })
      .then((redirectUrl: string) => {
        window.location.href = redirectUrl;
      })
      .catch(e => {
        console.log("Silent login failed", e);
        showLoginButton();
      });
};
